.home_body {
    background: url("/src/Home/background/giphy.gif") no-repeat center;
    background-size: cover;

    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    /* overflow: hidden;  */
}

.main-page {
    /*position: relative;*/
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.top-nav {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 120px;
    margin: 20px 0;
}

.top-nav span {
    cursor: pointer;
    font-size: 20px;
    position: static;
}

.top-nav.active::after {
    content: '🢗';
    position: absolute;
    top: 100%;
    left: 50%;
    font-size: 12px;
}

.side-nav {
    position: absolute;
    bottom: 10%;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
}

.side-nav a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}