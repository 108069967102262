body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#preloader {
  background: black url(./styles/videos/LOADINGSCREEN.gif) center;
  background-size: 100%;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  transition: opacity 1s ease;
  opacity: 1;
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-track{
  background-color: rgb(2, 2, 2);
}

::-webkit-scrollbar-thumb{
  background-color: #2c2c2c;
}

@media (max-width: 2375px) {

  #preloader {
    background-size: 150%;
  }

}

@media (max-width: 1650px) {

  #preloader {
    background-size: 80%;
  }
  
}

@media (max-width: 1350px) {

  #preloader {
    background-size: 70%;
  }
  
}

@media (max-width: 1150px) {

  #preloader {
    background-size: 90%;
  }
  
}

@media (max-width: 850px) {

  #preloader {
    background-size: 120%;
  }
  
}

@media (max-width: 650px) {

  #preloader {
    background-size: 150%;
  }
  
}

@media (max-width: 480px) {

  #preloader {
    background-size: 200%;
  }
  
}